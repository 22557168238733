import { defineStore } from "pinia";
import { useFeedback } from "~~/composables/feedback/useFeedback";
import { useUsersHabilitationsApi } from "~~/composables/users-habilitations/useUsersHabilitationsApi";
import type { IUserHabilitations, IUsersHabilitationsStore } from "~~/utils/interfaces/habilitations-interfaces";
import { useUsersHabilitationsValidation } from "~~/composables/users-habilitations/useUsersHabilitations";

export const useUsersHabilitationsStore = defineStore("usersHabilitations", {
  state: (): IUsersHabilitationsStore => {
    return {
      users_habilitations: null,
      user_habilitation_details: null,
      habilitations_list: null,
      user_habilitations_list: null,
      show_user_habilitation_detail_modal: false,
      show_csv_import_modal: false,
      user_error: null,
      habilitation_error: null,
      duration_years_error: null,
      selected_users_id_list: [],
      excluded_users_id_list: [],
    };
  },
  getters: {},
  actions: {
    async fetchAvailableHabilitations() {
      const usersHabilitationsApi = useUsersHabilitationsApi();
      const { data } = await usersHabilitationsApi.fetchAvailableHabilitations();

      if (data.value) {
        this.habilitations_list = data.value;
      }
    },

    async fetchUsersHabilitations() {
      const usersHabilitationsApi = useUsersHabilitationsApi();
      const { data } = await usersHabilitationsApi.fetchUsersHabilitations();

      if (data.value) {
        this.users_habilitations = data.value;
      }
    },

    async fetchUserHabilitationDetails(userHabilitationId: string) {
      const feedback = useFeedback();
      const usersHabilitationsApi = useUsersHabilitationsApi();
      const router = useRouter();

      const { data } = await usersHabilitationsApi.fetchUserHabilitationDetails(userHabilitationId);

      if (data.value) {
        this.user_habilitation_details = data.value;
        await router.push({
          query: { ...router.currentRoute.value.query, user_habilitation_id: userHabilitationId },
        });
      } else {
        feedback.error(`erreur`, "small");
      }
    },

    async fetchAllUserHabilitations(userId: string) {
      const feedback = useFeedback();
      const usersHabilitationsApi = useUsersHabilitationsApi();

      const { data } = await usersHabilitationsApi.fetchAllUserHabilitations(userId);

      if (data.value) {
        this.user_habilitations_list = data.value;
      } else {
        feedback.error(`erreur`, "small");
      }
    },
    async createUpdate(body: { name: string; duration_years: number }) {
      const userHabilitations = useUsersHabilitationsApi();
      const feedback = useFeedback();

      try {
        const { data } = await userHabilitations.createUpdate(body.name, body.duration_years);
        return data;
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },

    async uploadCertificate(userHabilitationId: string, file: any) {
      const usersHabilitationsApi = useUsersHabilitationsApi();
      const { data } = await usersHabilitationsApi.uploadCertificate(userHabilitationId, file);
      return data.value;
    },

    async removeCertificate(userHabilitationId: string) {
      const usersHabilitationsApi = useUsersHabilitationsApi();
      const { data } = await usersHabilitationsApi.removeCertificate(userHabilitationId);
      return data.value;
    },

    updateRow<K extends keyof IUserHabilitations>(userHabilitationId: string, key: K, value: IUserHabilitations[K]) {
      const rowIndex: number =
        this.users_habilitations?.data?.findIndex((el) => el.user_habilitation_id === userHabilitationId) ?? -1;
      if (this.users_habilitations?.data && key && value && rowIndex !== -1) {
        const row: IUserHabilitations = this.users_habilitations.data[rowIndex];
        row[key] = value;
      }
    },

    async fetchCertificateUrl(userHabilitationId: string) {
      const usersHabilitationsApi = useUsersHabilitationsApi();
      const { data } = await usersHabilitationsApi.fetchCertificateUrl(userHabilitationId);
      return data.value;
    },

    cleanValidationError() {
      this.user_error = null;
      this.habilitation_error = null;
      this.duration_years_error = null;
    },

    async validateUserHabilitationCreation() {
      const userHabilitationsValidation = useUsersHabilitationsValidation();
      const t$ = await userHabilitationsValidation.validateUsersHabilitationsCreation();
      if (t$.value.$errors) {
        this.user_error = t$.value.user_id.$silentErrors.map((m) => m.$message).join("");
        this.habilitation_error = t$.value.habilitation_name.$silentErrors.map((m) => m.$message).join("");
        this.duration_years_error = t$.value.duration_years.$silentErrors.map((m) => m.$message).join("");
      }
    },

    async validateUserHabilitationUpdate() {
      const userHabilitationsValidation = useUsersHabilitationsValidation();
      const t$ = await userHabilitationsValidation.validateUsersHabilitationsUpdate();
      if (t$.value.$errors) {
        this.user_error = t$.value.user_id.$silentErrors.map((m) => m.$message).join("");
        this.habilitation_error = t$.value.habilitation_name.$silentErrors.map((m) => m.$message).join("");
        this.duration_years_error = t$.value.duration_years.$silentErrors.map((m) => m.$message).join("");
      }
    },

    async exportUserHabilitationsCSV() {
      const feedback = useFeedback();
      const userHabilitationApi = useUsersHabilitationsApi();
      const { data } = await userHabilitationApi.exportUserHabilitationsCSV();
      if (data.value) {
        return data.value;
      } else {
        feedback.error(`erreur`, "small");
      }
    },

    async exportUserHabilitationsXLSX() {
      const feedback = useFeedback();
      const userHabilitationApi = useUsersHabilitationsApi();
      const { data } = await userHabilitationApi.exportUserHabilitationsXLSX();
      if (data.value) {
        return data.value;
      } else {
        feedback.error(`erreur`, "small");
      }
    },

    async fetchNotExcludedUserHabilitation(userIdList: string[]) {
      const feedback = useFeedback();
      const userHabilitationApi = useUsersHabilitationsApi();
      const { data } = await userHabilitationApi.fetchNotExcludedUserHabilitation(userIdList);
      if (data.value) {
        this.selected_users_id_list = data.value;
      } else {
        feedback.error(`erreur`, "small");
      }
    },
  },
});
