import { defineStore } from "pinia";
import { ProjectsApi, SessionsApi } from "@simbelapp/order-sdk";
import { startOfDay } from "date-fns";
import { useBookingsStore } from "../bookings/bookings.store";
import { useCatalogStore } from "../catalog/catalog.store";
import { useTrainingPartnersStore } from "../training-partners/training-partners.store";
import { useCatalogApi } from "~/composables/catalog/useCatalogApi";
import { useFeedback } from "~/composables/feedback/useFeedback";
import { useProjectApi } from "~/composables/projects/useProjectApi";
import { useSessionsApi } from "~/composables/sessions/useSessionsApi";
import { useProjectsStore } from "~/store/project/projects.store";
import { useRequestsStore } from "~/store/requests/requests.store";
import { useSessionItemStore } from "~/store/session-item/session-item-store";
import { useSessionsStore } from "~/store/sessions/sessions-store";
import { useTrainingsStore } from "~/store/trainings/trainings.store";
import { FinancingModes, ItemType, MicroserviceEnum, VatEnum } from "~/utils/enums/common.enums";
import { ProjectNavEnum, ProjectTypeEnum } from "~/utils/enums/project.enums";
import { SessionItemModalityEnum, SessionItemTypeEnum } from "~/utils/enums/session-items.enums";
import type { ICreateProjectCore, IProjectApiPayload, IProjectListItem } from "~/utils/interfaces/project-interfaces";
import { usePagination } from "~~/composables/paginations/usePagination";
import { useTrainingsPlansApi } from "~~/composables/trainings-plans/useTrainingsPlansApi";
import { useSessionDetailsStore } from "~~/store/sessions/session-details-store";
import { useCreateTrainingStore } from "~~/store/trainings/create-training.store";
import type { IFinancingComponent, IFooterActions } from "~~/utils/interfaces/common-interfaces";
import type { ITraining } from "~~/utils/interfaces/trainings-interface";
import { BookingStatusEnum } from "~/utils/enums/bookings-events.enums";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { handleValueInput } from "~/utils/helpers/handleValueInput";
import { TrainingTypeEnum } from "~/utils/enums/training.enums";

type Bucket = {
  financing_mode: string;
  amount: number;
};

type UserBuckets = {
  financing_mode: { name: string };
  price_excl_tax: number;
};

export const useCreateProjectCoreStore = defineStore("createProjectCoreStore", {
  state: (): ICreateProjectCore => {
    return {
      project: {
        project_id: null,
        training: {
          training_id: null,
          project_title: null,
          project_item: null,
        },
        session_item: {
          session_item_id: null,
          session_item_type: null,
        },
        session: {
          session_id: null,
          session_list: [],
          error: {},
          create_session_step: false,
        },
        users: {
          users_id: null,
          inscribed: null,
          selected: null,
        },
        financing: {
          price_excl_tax: null,
          budget_excl_tax: null,
          opco_excl_tax: null,
          cpf_excl_tax: null,
          capacity: null,
          users_buckets: [],
        },
        reportingPeriod: null,
        status: null,
        comment: null,
        notification: {
          notification_type: BookingStatusEnum.BOOKING_CONFIRMED,
          do_notify_main_contact: false,
          main_contact_email: null,
        },
        source_type: null,
        source_id: null,
        project_type: null,
        errors_edit: null,
        start_date: null,
        end_date: null,
        format: null,
        duration: null,
        location: null,
        designation: null,
        nb_max_attendees: null,
        nb_min_attendees: null,
        overbooking_not_allowed: false,
      },
      nav: ProjectNavEnum.TRAINING,
      show_exit: false,
      show_modal: false,
      previous_modal_state: {
        modal_funnel: false,
        modal_session_details_complete: false,
        modal_show_edit: false,
        modal_show_multiple: false,
      },
      show_confirm: false,
      show_confirm_send_notification: false,
      show_edit: false,
      show_session_details_complete: false,
      edit_mode: false,
      fetch_complete_done: false /** loading state for better UX **/,
      redirect_session_id: null,
      previous_project_id: null,
      previous_session_id: null,
      show_session_already_billed_completed: false,
    };
  },
  getters: {
    isTrainingChosen(): boolean {
      return !!this.project.training.training_id;
    },
    isProjectItemFormatExist(): boolean {
      return !this.project.training.project_title && !!(this.project.training.project_item as IProjectListItem)?.format;
    },
    isProjectItemDurationExist(): boolean {
      return (
        !this.project.training.project_title && !!(this.project.training.project_item as IProjectListItem)?.duration
      );
    },
    isProjectItemExist(): boolean {
      return !!this.project.training.project_item && !this.project.training.project_title;
    },
    isProjectTitleExist(): boolean {
      return !!this.project.training.project_title;
    },
    isSessionItemChosen(): boolean {
      return !!(this.isTrainingChosen && this.project.session_item.session_item_id);
    },
    isTrainingStatusDraft(): boolean {
      return !!(this.project.training.training_id?.status === "draft");
    },
    getSessionItemType(): SessionItemTypeEnum {
      return this.project.session_item.session_item_type as SessionItemTypeEnum;
    },
    isSessionChosen(): boolean {
      return !!(this.isTrainingChosen && this.isSessionItemChosen && this.project.session.session_id);
    },
    isIndividualSessionChosen(): boolean {
      return this.project.session?.session_list?.find(
        (s: any) => s.isCheck && s.session_type === SessionItemTypeEnum.INDIVIDUAL,
      );
    },
    isGroupSessionChosen(): boolean {
      return this.project.session?.session_list?.find(
        (s: any) => s.isCheck && s.session_type === SessionItemTypeEnum.GROUP,
      );
    },
    isCpfEligible(): boolean {
      return !this.project.training.training_id || this.project.training.training_id?.is_cpf_eligible;
    },
    isOpcoEligible(): boolean {
      return !this.project.training.training_id || this.project.training.training_id?.is_opco_eligible;
    },
    hasPriceError(): boolean {
      return (
        Number(this.project.financing.price_excl_tax ?? 0) !==
        Number(this.project.financing.opco_excl_tax ?? 0) +
          Number(this.project.financing.cpf_excl_tax ?? 0) +
          Number(this.project.financing.budget_excl_tax ?? 0)
      );
    },
    isGroupAvailable(): boolean {
      return this.isTrainingChosen && !!this.project.training.training_id?.group_session_item;
    },
    isIndividualAvailable(): boolean {
      return this.isTrainingChosen && !!this.project.training.training_id?.individual_session_item;
    },
    isTrainingOnDemand(): boolean {
      return (
        this.isTrainingChosen &&
        this.project.session_item.session_item_id?.modality === SessionItemModalityEnum.ON_DEMAND
      );
    },

    getFinancingInfo(): IFinancingComponent {
      const budget = Number.isFinite(this.project.financing.budget_excl_tax)
        ? this.project.financing.budget_excl_tax
        : 0;

      const opco = Number.isFinite(this.project.financing.opco_excl_tax) ? this.project.financing.opco_excl_tax : 0;

      const cpf = Number.isFinite(this.project.financing.cpf_excl_tax) ? this.project.financing.cpf_excl_tax : 0;

      const financingInfos: IFinancingComponent = {
        isCPFEligible: this.isCpfEligible,
        isOpcoEligible: this.isOpcoEligible,
        isWithSession: this.isSessionChosen,
        isWithTraining: this.isTrainingChosen,
        priceExclTax: this.project.financing.price_excl_tax,
        totalAttendees: this.project.users?.users_id?.length ?? null,
        opcoAmountExclTax: opco,
        budgetAmountExclTax: budget,
        cpfAmountExclTax: cpf,
        groupPriceExclTax: this.project.training.training_id?.group_price_excl_tax ?? null,
        individualPriceExclTax: this.project.training.training_id?.individual_price_excl_tax ?? null,
        maxNbAttendees:
          this.project.session.session_id?.max_nb_attendees ??
          this.project.training.training_id?.group_session_item?.max_nb_attendees ??
          null,
        isIndividualSession:
          (this.project.training.training_id?.individual_session_item &&
            this.getSessionItemType === SessionItemTypeEnum.INDIVIDUAL) ??
          false,
        vatType: Number.isFinite(this.project.training.training_id?.individual_session_item?.vat)
          ? VatEnum.TTC
          : VatEnum.HT,
        usersBuckets: this.project.financing.users_buckets,
        trainingIsDraft: this.isTrainingStatusDraft ?? false,
        trainingIsVisible: this.project.training.training_id?.visible ?? true,
      };

      return financingInfos;
    },
    getProjectCreateApiPayload(): IProjectApiPayload {
      const sessionChoosen = this.project.session.session_list?.find((s: any) => s.isCheck);

      const editInfos = {
        start_date: this.project?.start_date,
        end_date: this.project?.end_date,
        format: this.project?.format?.name,
        designation: this.project?.designation,
        location: this.project?.location,
        duration: this.project?.duration,
        max_nb_attendees: this.project?.nb_max_attendees,
        min_nb_attendees: this.project?.nb_min_attendees,
        overbooking_not_allowed: this.project?.overbooking_not_allowed,
        // capacity: this.project.session.session_draft?.capacity,
      };

      const existPrevious = this.previous_project_id || this.previous_session_id;

      const payload = {
        project_id: this.project.project_id,
        ...editInfos,
        training_id: this.project.training.training_id?.training_id ?? null,
        session_item_type:
          !existPrevious && this.project.session_item.session_item_type
            ? this.project.session_item.session_item_type
            : null,
        session_item_id:
          !existPrevious && this.project.session_item.session_item_id?.session_item_id
            ? this.project.session_item.session_item_id?.session_item_id
            : null,
        session_id: sessionChoosen?.sessionId,
        ...((sessionChoosen?.sessionItemType === SessionItemTypeEnum.GROUP ||
          (sessionChoosen?.sessionItemType === SessionItemTypeEnum.INDIVIDUAL &&
            !this.project.users.users_id?.length)) && {
          budget_excl_tax: this.project.financing.budget_excl_tax,
          opco_excl_tax: this.project.financing.opco_excl_tax,
          cpf_excl_tax: this.project.financing.cpf_excl_tax,
        }),
        // handle case without session item type
        ...(!sessionChoosen?.sessionItemType && {
          budget_excl_tax: this.project.financing.budget_excl_tax,
          opco_excl_tax: this.project.financing.opco_excl_tax,
          cpf_excl_tax: this.project.financing.cpf_excl_tax,
        }),
        reporting_period_id:
          !existPrevious && this.project.reportingPeriod?.reporting_id
            ? this.project.reportingPeriod?.reporting_id
            : null,
      };

      if (this.project.users.users_id?.length) {
        if (this.project.financing.users_buckets?.length) {
          Object.assign(payload, {
            project_users: this.project.financing.users_buckets?.map((u: any) => {
              return {
                user_id: u.user_id,
                request_id: u?.request_id,
                ...((this.project.session_item.session_item_type === SessionItemTypeEnum.INDIVIDUAL ||
                  sessionChoosen?.sessionItemType === SessionItemTypeEnum.INDIVIDUAL) && {
                  budget: Number.isFinite(u.budget) ? u.budget : 0,
                  opco: Number.isFinite(u.opco) ? u.opco : 0,
                  cpf: Number.isFinite(u.cpf) ? u.cpf : 0,
                }),
                previous_project_id: this.previous_project_id,
                previous_session_id: this.previous_session_id,
              };
            }),
          });
        } else {
          Object.assign(payload, {
            project_users: this.project.users.users_id?.map((u: any) => {
              return {
                user_id: u.user_id,
                request_id: u?.request_id,
                previous_project_id: this.previous_project_id,
              };
            }),
          });
        }
      }

      return payload;
    },
    getProjectUpdateApiPayload(): IProjectApiPayload {
      const sessionChoosen = this.project.session.session_list?.find((s: any) => s.isCheck);

      const editInfos = {
        start_date: this.project?.start_date,
        end_date: this.project.end_date,
        format: this.project.format?.name,
        designation: this.project?.designation,
        location: this.project?.location,
        duration: this.project?.duration,
        max_nb_attendees: this.project?.nb_max_attendees,
        min_nb_attendees: this.project?.nb_min_attendees,
        overbooking_not_allowed: this.project?.overbooking_not_allowed,
        // capacity: this.project.session.session_draft?.capacity,
      };

      const payload = {
        project_id: this.project.project_id,
        ...editInfos,
        training_id: this.project.training.training_id?.training_id ?? null,
        session_item_type: this.project.session_item.session_item_type
          ? this.project.session_item.session_item_type
          : null,
        session_item_id: this.project.session_item.session_item_id?.session_item_id
          ? this.project.session_item.session_item_id?.session_item_id
          : null,
        session_id: sessionChoosen?.sessionId,
        ...((sessionChoosen?.sessionItemType === SessionItemTypeEnum.GROUP ||
          (sessionChoosen?.sessionItemType === SessionItemTypeEnum.INDIVIDUAL &&
            !this.project.users.users_id?.length)) && {
          budget_excl_tax: this.project.financing.budget_excl_tax,
          opco_excl_tax: this.project.financing.opco_excl_tax,
          cpf_excl_tax: this.project.financing.cpf_excl_tax,
        }),
        // handle case without session item type
        ...(!sessionChoosen?.sessionItemType && {
          budget_excl_tax: this.project.financing.budget_excl_tax,
          opco_excl_tax: this.project.financing.opco_excl_tax,
          cpf_excl_tax: this.project.financing.cpf_excl_tax,
        }),
        reporting_period_id: this.project.reportingPeriod?.reporting_id ?? null,
      };

      if (this.project.users.users_id?.length) {
        if (this.project.financing.users_buckets?.length) {
          Object.assign(payload, {
            project_users: this.project.financing.users_buckets?.map((u: any) => {
              return {
                user_id: u.user_id,
                request_id: u?.request_id,
                ...((this.project.session_item.session_item_type === SessionItemTypeEnum.INDIVIDUAL ||
                  sessionChoosen?.sessionItemType === SessionItemTypeEnum.INDIVIDUAL) && {
                  budget: Number.isFinite(u.budget) ? u.budget : 0,
                  opco: Number.isFinite(u.opco) ? u.opco : 0,
                  cpf: Number.isFinite(u.cpf) ? u.cpf : 0,
                }),
                previous_project_id: this.previous_project_id,
                previous_session_id: this.previous_session_id,
              };
            }),
          });
        } else {
          Object.assign(payload, {
            project_users: this.project.users.users_id?.map((u: any) => {
              return {
                user_id: u.user_id,
                request_id: u?.request_id,
                previous_project_id: this.previous_project_id,
              };
            }),
          });
        }
      }

      return payload;
    },
  },
  actions: {
    setProjectFinancing(financingDatas: IFinancingComponent): void {
      const { floatNumber } = handleValueInput();
      const t = floatNumber(financingDatas.priceExclTax || 0);
      const b = floatNumber(financingDatas.budgetAmountExclTax || 0);
      const o = floatNumber(financingDatas.opcoAmountExclTax || 0);
      const c = floatNumber(financingDatas.cpfAmountExclTax || 0);

      this.project.financing.budget_excl_tax = b;
      this.project.financing.opco_excl_tax = o;
      this.project.financing.cpf_excl_tax = c;
      this.project.financing.capacity = financingDatas.maxNbAttendees ?? null;
      this.project.financing.price_excl_tax = t;

      financingDatas.usersBuckets?.forEach((u: any) => {
        let b: number = 0;
        // If we're in the case of adding a user true the add funnel we need to set the default price using the training
        if (this.show_modal && this.project?.training?.training_id?.default_price) {
          b = floatNumber(this.project.training.training_id?.default_price);
        } else {
          b = floatNumber(u.budget || 0);
        }
        const t = floatNumber((u.budget || 0) + (u.opco || 0) + (u.cpf || 0));
        const o = floatNumber(u.opco || 0);
        const c = floatNumber(u.cpf || 0);

        u.budget = b;
        u.opco = o;
        u.cpf = c;
        u.total = t;
      });

      this.project.financing.users_buckets = financingDatas.usersBuckets ?? null;
    },
    setProjectFinancingGroup(financingDatas: IFinancingComponent): void {
      const { floatNumber } = handleValueInput();
      const t = floatNumber((financingDatas.budgetAmountExclTax || 0) + (financingDatas.opcoAmountExclTax || 0));
      const b = floatNumber(financingDatas.budgetAmountExclTax || 0);
      const o = floatNumber(financingDatas.opcoAmountExclTax || 0);
      const c = floatNumber(financingDatas.cpfAmountExclTax || 0);

      this.project.financing.budget_excl_tax = b;
      this.project.financing.opco_excl_tax = o;
      this.project.financing.cpf_excl_tax = c;
      this.project.financing.capacity = financingDatas.maxNbAttendees ?? null;
      this.project.financing.price_excl_tax = t;

      financingDatas.usersBuckets?.forEach((u: any) => {
        const b = floatNumber(financingDatas.budgetAmountExclTax || 0) / (financingDatas.totalAttendees || 1);
        const o = floatNumber((financingDatas.opcoAmountExclTax || 0) / (financingDatas.totalAttendees || 1));
        const t = floatNumber(financingDatas.budgetAmountExclTax || 0) + (financingDatas.opcoAmountExclTax || 0);

        u.budget = b;
        u.cpf = 0;
        u.opco = o;
        u.total = t;
      });

      this.project.financing.users_buckets = financingDatas.usersBuckets;
    },
    footersActions(): IFooterActions[] {
      return [
        {
          name: "Changer de session",
          logoName: "create",
          action: () => {
            this.changeSession();
          },
        },
      ];
    },
    openProjectModal(delegateShow = false): void {
      const sessionStore = useSessionsStore();
      sessionStore.session_item_sessions = null;
      sessionStore.session_item_sessions_draft = null;

      this.$reset();
      if (!delegateShow) {
        this.show_modal = true;
      }
    },
    async changeSession(sessionConfirmed = false) {
      const sessionDetailsStore = useSessionDetailsStore();
      const sessionStore = useSessionsStore();
      const selectedUsers = this.project.users.selected;

      this.openProjectModal(true);

      if (sessionDetailsStore.session_details?.id) {
        await this.processCompleteSessionDetail(sessionDetailsStore.session_details.id, true, sessionConfirmed);
      }

      if (sessionConfirmed && sessionDetailsStore.session_details?.sessionId) {
        this.previous_project_id = "";
        this.previous_session_id = sessionDetailsStore.session_details?.sessionId;
      }

      this.project.users.users_id = selectedUsers;
      this.project.project_type = ProjectTypeEnum.NEW_PROJECT;
      this.previous_modal_state.modal_show_multiple = true;
      this.previous_project_id = this.project.project_id;
      this.project.session.session_list = [];

      if (this.project.training.training_id?.training_id) {
        this.project.session.session_list = await sessionStore.fetchSessionsByTrainingId(
          this.project.training.training_id?.training_id,
        );
        this.project.session.session_list?.forEach((session: any) => {
          session.isCheck = false;
        });
      }

      this.show_modal = true;
    },
    async performImpactsTrainingChange() {
      const sessionStore = useSessionsStore();
      this.project.session.session_list = [];
      if (this.project.training.training_id?.training_id) {
        this.project.training.project_title = null;
        this.project.training.project_item = null;
      }

      sessionStore.session_item_sessions = null;
      sessionStore.session_item_sessions_draft = null;

      this.project.session_item.session_item_type = null;
      this.project.session_item.session_item_id = null;
      this.project.session.session_id = null;
      this.project.financing.price_excl_tax = null;
      this.project.financing.budget_excl_tax = null;
      this.project.financing.cpf_excl_tax = null;
      this.project.financing.opco_excl_tax = null;

      const types = this.project.training.training_id?.session_items_types;
      const isIndividualSession = types?.some((type) => type === SessionItemTypeEnum.INDIVIDUAL);
      const isGroupSession = types?.some((type) => type === SessionItemTypeEnum.GROUP);

      if (!isGroupSession && isIndividualSession && this.project.training.training_id?.individual_session_item) {
        this.project.session_item.session_item_id = this.project.training.training_id?.individual_session_item;
        this.project.session_item.session_item_type = SessionItemTypeEnum.INDIVIDUAL;
        this.project.nb_max_attendees =
          this.project.training.training_id?.individual_session_item?.max_nb_attendees ?? null;
        this.project.nb_min_attendees =
          this.project.training.training_id?.individual_session_item?.min_nb_attendees ?? null;
        this.project.overbooking_not_allowed =
          this.project.training.training_id?.individual_session_item?.overbooking_not_allowed ?? false;
      }

      if (this.project.session_item.session_item_type) {
        this.setSessionItemsSessions(this.project.session_item?.session_item_type);
      }

      if (this.project.training.training_id?.training_partner_id) {
        const trainingPartnersStore = useTrainingPartnersStore();
        const tp = await trainingPartnersStore.findOneTrainingPartner(
          this.project.training.training_id?.training_partner_id,
        );

        this.project.notification.main_contact_email = tp?.main_contact_email;
      }

      if (this.project.training.training_id?.training_partner?.main_contact_email) {
        this.project.notification.main_contact_email =
          this.project.training.training_id?.training_partner?.main_contact_email;
      }

      this.project.session.session_list = [];
      if (this.project.training.training_id?.training_id) {
        this.project.session.session_list = await sessionStore.fetchSessionsByTrainingId(
          this.project.training.training_id?.training_id,
        );
        this.project.session.session_list?.forEach((session: any) => {
          session.isCheck = false;
        });
      }
    },
    async performImpactsSessionChange(session: any) {
      const sessionApi = useSessionsApi();
      const trainingPlanApi = useTrainingsPlansApi();

      this.project.users.inscribed = null;

      if (session.session_id) {
        this.project.session.session_id = session;
        if (this.project.source_type !== ItemType.REQUEST) {
          const { data } = await trainingPlanApi.fetchOneTrainingPlanDetailsAttendees(
            session.session_id,
            ItemType.SESSION,
          );
          this.project.users.inscribed = data.value?.filter((a: any) => a.session_id !== "fake") ?? null;
        }
      }
    },
    async setSessionItemsSessions(val: SessionItemTypeEnum, dontLoadValues: boolean = false) {
      const sessionsStore = useSessionsStore();
      if (this.project.training.training_id && val) {
        const session_items: Record<string, any> = {
          group: this.project.training.training_id?.group_session_item,
          individual: this.project.training.training_id?.individual_session_item,
        };

        this.project.session_item.session_item_id = session_items[val];
        this.project.financing.price_excl_tax = session_items[val]?.price_excl_tax;
        this.project.financing.budget_excl_tax = session_items[val]?.price_excl_tax;

        if (this.project.session_item?.session_item_id) {
          if (this.project.session_item.session_item_id.modality !== SessionItemModalityEnum.ON_DEMAND) {
            await sessionsStore.fetchSessionItemSessions(
              this.project.session_item?.session_item_id.session_item_id as string,
            );
          } else if (this.project.session_item.session_item_id.modality === SessionItemModalityEnum.ON_DEMAND) {
            this.setOnDemandDates();
          }

          if (this.project.session_item.session_item_type === SessionItemTypeEnum.INDIVIDUAL && !dontLoadValues) {
            this.project.financing.users_buckets?.forEach((user: any) => {
              user.budget = this.project.financing.budget_excl_tax;
              user.opco = this.project.financing.opco_excl_tax;
              user.cpf = this.project.financing.cpf_excl_tax;
            });
          }
        }
      }
    },
    loadUsersFinancing() {
      const requestsStore = useRequestsStore();

      let source_budget: number | null = null;

      if (
        this.project.source_type === ItemType.REQUEST &&
        Number.isFinite(requestsStore.request_details?.price_excl_tax)
      ) {
        source_budget = Number(requestsStore.request_details?.price_excl_tax);
      } else if (
        this.isSessionItemChosen &&
        Number.isFinite(this.project.session_item.session_item_id?.price_excl_tax)
      ) {
        source_budget = Number(this.project.session_item.session_item_id?.price_excl_tax);
      }
      if (this.project.users.users_id?.length) {
        const training = this.project.training.training_id;
        const isIndividualSession =
          training?.individual_session_item && this.getSessionItemType === SessionItemTypeEnum.INDIVIDUAL;
        if (this.project.project_type === ProjectTypeEnum.REGULATION && isIndividualSession && source_budget) {
          source_budget = source_budget * (this.project.users.users_id.length || 1);
          this.project.financing.price_excl_tax = source_budget;
          this.project.financing.budget_excl_tax = source_budget;
        }
        if (!this.show_edit) {
          this.project.financing.users_buckets = null;

          this.project.financing.users_buckets = this.project.users.users_id?.map((u) => {
            return {
              user_id: u.user_id,
              request_id: u?.request_id,
              display: u.firstname && u.lastname ? `${u.firstname} ${u.lastname}` : u.email ?? "",
              budget: Number(source_budget),
              cpf: 0,
              opco: 0,
              total: Number.isFinite(source_budget) ? Number(source_budget) : 0,
            };
          });
        }
      } else if (!this.show_edit && !this.project.users.users_id?.length) {
        this.project.financing.users_buckets = [];
      }
    },
    setOnDemandDates() {
      const training = this.project.training.training_id;
      if (this.isTrainingOnDemand) {
        this.project.session.create_session_step = true;

        const end_date = training?.duration_days
          ? new Date(new Date().setDate(new Date().getDate() + training.duration_days))
          : new Date();

        this.project.start_date = startOfDay(new Date());
        this.project.end_date = startOfDay(end_date);
      }
    },
    async updatedTrainingAndSessionItem() {
      const trainingsStore = useTrainingsStore();
      const sessionItemStore = useSessionItemStore();

      if (
        this.project.training?.training_id &&
        !Number.isFinite(this.project.training.training_id?.duration_hours) &&
        Number.isFinite(this.project.training.training_id.new_training_duration_hours)
      ) {
        await trainingsStore.createUpdateTraining({
          training_id: this.project.training.training_id.training_id,
          duration_hours: this.project.training.training_id?.new_training_duration_hours,
        });

        this.project.training.training_id.duration_hours = this.project.training.training_id
          .new_training_duration_hours as number;
      }

      if (this.project.training?.training_id?.new_session_items_format) {
        if (
          this.project?.training.training_id?.group_session_item?.session_item_id &&
          this.project.training.training_id?.new_session_items_format
        ) {
          this.project.training.training_id.group_session_item.format =
            this.project.training.training_id.new_session_items_format;
          await sessionItemStore.updateSessionItem(this.project.training, {
            session_item_id: { ...this.project.training.training_id?.group_session_item },
            session_item_type: SessionItemTypeEnum.GROUP,
          });
        }

        if (
          this.project.training.training_id?.individual_session_item?.session_item_id &&
          this.project.training.training_id?.new_session_items_format
        ) {
          this.project.training.training_id.individual_session_item.format =
            this.project.training.training_id.new_session_items_format;
          await sessionItemStore.updateSessionItem(this.project.training, {
            session_item_id: { ...this.project.training.training_id?.individual_session_item },
            session_item_type: SessionItemTypeEnum.INDIVIDUAL,
          });
        }
      }
    },
    async getProjectById(id: string) {
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, ProjectsApi);
      const feedback = useFeedback();
      try {
        const results = await apiInstance.projectsControllerGetProject({ projectId: id });
        const project = { isCheck: false, ...results };

        this.project.session.session_list?.push(project);
      } catch (e) {
        feedback.error(`erreur lors de la recherche de projets`, "small");
      }
    },
    async createProject(removeProjectID: boolean = true) {
      const projectApi = useProjectApi();
      const feedback = useFeedback();
      const sessionStore = useSessionsStore();
      const { session } = this.project;

      if (!this.project.users.users_id?.length) {
        this.loadUsersFinancing();
      }

      if (this.isTrainingStatusDraft) {
        await this.updatedTrainingAndSessionItem();
      }

      let payload = this.getProjectCreateApiPayload;
      if (removeProjectID) {
        payload = { ...payload, project_id: null };
      }
      const { data } = await projectApi.createProject({
        title: this.project.training.project_title,
        ...payload,
      });

      this.$reset();

      if (data.value) {
        feedback.success(`Brouillon sauvegardé`, "small");

        const usePaginate = usePagination();
        await usePaginate.fetchData();

        this.redirectProject(data.value.project_id as string, ItemType.PROJECT);
      } else {
        feedback.error(`erreur lors de la sauvegarde du brouillon`, "small");
      }

      this.previous_modal_state.modal_show_multiple = false;
    },
    async editProject() {
      const projectApi = useProjectApi();
      const feedback = useFeedback();

      if (!this.project.users.users_id?.length) {
        this.loadUsersFinancing();
      }

      if (this.isTrainingStatusDraft) {
        await this.updatedTrainingAndSessionItem();
      }

      const { data } = await projectApi.updateProject(this.project.project_id!, {
        title: this.project.training.project_title,
        ...this.getProjectUpdateApiPayload,
      });

      this.$reset();

      if (data.value) {
        feedback.success(`Brouillon sauvegardé`, "small");

        const usePaginate = usePagination();
        await usePaginate.fetchData();
        this.redirectProject(data.value.project_id as string, ItemType.PROJECT);
      } else {
        feedback.error(`erreur lors de la sauvegarde du brouillon`, "small");
      }
    },
    async confirmProject() {
      const projectApi = useProjectApi();
      const feedback = useFeedback();
      const catalogStore = useCatalogStore();
      let sessionId = null;

      // We don't know what it is used for.  Actually, it was making the trainings visible.
      // await catalogStore.changeVisibility([this.project.training.training_id?.training_id as string], true, false);
      let data = null;
      if (this.previous_modal_state.modal_show_edit) {
        data = await projectApi.confirmUserProject({
          ...this.getProjectUpdateApiPayload,
          comment: this.project.comment,
          bookings_status: this.project.notification.notification_type,
        });
      } else {
        const sessionDetailsStore = useSessionDetailsStore();
        const trainingsStore = useTrainingsStore();

        const trainingCategories = this.project.training.training_id?.categories?.map((c) => c.category_id);

        await trainingsStore.createUpdateTraining({
          training_id: this.project.training.training_id?.training_id,
          is_not_billable:
            sessionDetailsStore.session_details?.sessionId &&
            sessionDetailsStore.session_details.trainingType !== TrainingTypeEnum.INTERNAL
              ? sessionDetailsStore.session_details?.isNotBillable
              : this.project.training.training_id?.is_not_billable,
          ...(trainingCategories?.length && { categories: trainingCategories }),
        });

        data = await projectApi.confirmProject({
          ...this.getProjectUpdateApiPayload,
          comment: this.project.comment,
          bookings_status: this.project.notification.notification_type,
          training_partner_email:
            this.project.notification.do_notify_main_contact && this.project.notification.main_contact_email
              ? this.project.notification.main_contact_email
              : null,
        });
      }

      if (data.error.value) {
        if (data.error.value?.data?.message === "Users already invited to this session") {
          feedback.error(`Un des participants est déjà inscrit à cette session`, "small");
          return;
        }
        feedback.error(`erreur lors de la confirmation du project`, "small");
        return;
      }
      sessionId = data.data.value.session_id;

      if (sessionId) {
        feedback.greeting(`Nouvelle session créée`, "small");
        this.redirect_session_id = sessionId;
        if (BookingStatusEnum.BOOKING_CONFIRMED === this.project.notification.notification_type) {
          this.show_confirm = false;
          this.show_confirm_send_notification = true;
        } else {
          this.redirectProject(sessionId, ItemType.SESSION);
        }
      } else {
        feedback.error(`erreur lors de la confirmation du project`, "small");
      }
    },

    async redirectProject(id: string, type: ItemType) {
      const $router = useRouter();
      const routeName: string = $router.currentRoute.value.name as string;

      // We don't want to redirect to the session details page when it's a training admin
      if (routeName.includes("my-sessions")) {
        return;
      }

      const sessionDetailsStore = useSessionDetailsStore();
      const usePaginate = usePagination();
      await usePaginate.fetchData();
      this.$reset();

      if (routeName.includes("training-plan") || routeName.includes("training-plan")) {
        await sessionDetailsStore.showMySession(id, type);
      } else {
        return navigateTo({
          path: `/training-plan`,
          query: {
            id,
            type,
          },
        });
      }
    },

    async createBookings() {
      if (!this.project.financing.users_buckets?.length) {
        return;
      }

      if (this.isTrainingStatusDraft) {
        await this.updatedTrainingAndSessionItem();
      }

      const bookingsStore = useBookingsStore();
      const feedback = useFeedback();

      const ses = this.project.session.session_list?.find((s) => s.isCheck);
      const opco = (this.project.financing.opco_excl_tax || 0) / (this.project.financing.users_buckets.length || 1);
      const cpf = (this.project.financing.cpf_excl_tax || 0) / (this.project.financing.users_buckets.length || 1);
      const budget = (this.project.financing.budget_excl_tax || 0) / (this.project.financing.users_buckets.length || 1);

      const data = await bookingsStore.createBookings({
        users_to_invite: this.project.financing.users_buckets?.map((u) => {
          return {
            user_id: u.user_id,
            previous_project_id: this.previous_project_id,
            previous_session_id: this.previous_session_id,
            opco: opco || undefined,
            cpf: cpf || undefined,
            budget: budget || undefined,
          };
        }),
        session_id: ses?.sessionId,
        training_id: this.project.training.training_id?.training_id,
        comment: this.project.comment,
        do_not_notify: this.project.notification.do_notify_main_contact,
        bookings_status: this.project.notification.notification_type,
        training_partner_email:
          this.project.notification.do_notify_main_contact && this.project.notification.main_contact_email
            ? this.project.notification.main_contact_email
            : null,
      });

      if (data.error.value) {
        if (data.error.value?.data?.message === "Users already invited to this session") {
          feedback.error(`Un des participants est déjà inscrit à cette session`, "small");
          return;
        }
        feedback.error(`erreur lors de la confirmation du project`, "small");
        return;
      }
      const sessionId = data.data.value.session_id;

      if (sessionId) {
        feedback.greeting(`Nouvelle session créée`, "small");
        this.redirect_session_id = sessionId;
        if (BookingStatusEnum.BOOKING_CONFIRMED === this.project.notification.notification_type) {
          this.show_confirm = false;
          this.show_confirm_send_notification = true;
        } else {
          this.redirectProject(sessionId, ItemType.SESSION);
        }
      } else {
        feedback.error(`erreur lors de la confirmation du project`, "small");
      }
    },

    async addUsersToProject() {
      const projectApi = useProjectApi();
      const project = this.project;
      const selectedProject = project.session?.session_list?.find(
        (item: any) => item.isCheck && item.status === ItemType.PROJECT,
      );
      const users = project.users.selected || project.users.users_id;

      await projectApi.addUsersToProject({
        project_id: selectedProject.projectId,
        project_users: users?.map((u) => {
          return {
            previous_project_id: this.previous_project_id,
            previous_session_id: this.previous_session_id,
            user_id: u.user_id,
            request_id: u?.request_id || null,
          };
        }),
      });
    },

    async processHRRequest() {
      this.$reset();

      const requestsStore = useRequestsStore();
      const catalogApi = useCatalogApi();
      const request_details = requestsStore.request_details;

      if (request_details?.request_id && request_details.not_processed) {
        this.project.source_id = request_details?.request_id;
        this.project.source_type = ItemType.REQUEST;

        if (request_details?.training_id) {
          const { data } = await catalogApi.fetchEmployeeCatalog({
            trainingId: request_details?.training_id,
          });

          this.project.training.training_id = data.value.data?.find(Boolean);
        }

        if (request_details?.unqualified) {
          this.project.training.project_title = request_details.employee_comment;
          this.project.training.project_item = request_details.employee_comment;
        }

        this.project.session_item.session_item_id = request_details?.session_id?.session_item_id;
        this.project.session_item.session_item_type = request_details?.session_id?.session_item_id.type;

        if (this.isTrainingOnDemand) {
          this.setOnDemandDates();
        } else if (request_details?.session_id) {
          this.project.session.session_id = request_details?.session_id;
          this.project.session.create_session_step = false;
        }

        let price_excl_tax: number | null = null;

        if (request_details.price_excl_tax) {
          price_excl_tax = request_details.price_excl_tax;
        } else if (request_details?.session_id?.session_item_id?.price_excl_tax) {
          price_excl_tax = request_details?.session_id?.session_item_id?.price_excl_tax;
        }

        this.project.financing.budget_excl_tax = price_excl_tax;
        this.project.financing.price_excl_tax = price_excl_tax;

        if (request_details?.user_id) {
          /**
           * API call return an array whereas its a findOne, but here we can use it to init the null array, may be legacy latter
           */

          this.project.users.users_id = [
            {
              request_id: request_details?.request_id,
              user_id: request_details?.user_id.user_id,
              id: this.project.project_id,
              firstname: request_details?.user_id.firstname ?? null,
              lastname: request_details?.user_id.lastname ?? null,
              email: request_details?.user_id.email ?? null,
              display_value: request_details?.user_id.display_value ?? null,
            },
          ];

          this.loadUsersFinancing();
        }

        this.project.session.session_list = [];
        if (this.project.training.training_id?.training_id) {
          const sessionStore = useSessionsStore();
          const requestsStore = useRequestsStore();

          const { request_details } = requestsStore;
          this.project.session.session_list = await sessionStore.fetchSessionsByTrainingId(
            this.project.training.training_id?.training_id,
          );
          this.project.session.session_list?.forEach((session: any) => {
            request_details?.session_id?.session_id && request_details?.session_id?.session_id === session.sessionId
              ? (session.isCheck = true)
              : (session.isCheck = false);
          });
        }

        this.show_modal = true;
      }
    },
    async processCompletedIndividual() {
      const sessionStore = useSessionsStore();
      const projectApi = useProjectApi();
      const projectStore = useProjectsStore();
      const createTrainingStore = useCreateTrainingStore();

      this.$reset();
      sessionStore.session_item_sessions = null;
      sessionStore.session_item_sessions_draft = null;

      sessionStore.show_session_modal = false;
      this.fetch_complete_done = false;
      this.show_edit = true;

      if (projectStore.training_plan_project_individual) {
        const { project_id, user_id } = projectStore.training_plan_project_individual;
        const { data } = await projectApi.findDetailsProjectIndividual(project_id, user_id);

        if (data.value && user_id) {
          this.project.project_type = ProjectTypeEnum.NEW_PROJECT;
          this.project.source_id = data.value?.source_id;
          this.project.source_type = data.value?.source_type;

          this.project.project_id = data.value.project_id;

          // TODO Refactor this to only user createTrainingStore
          if (data.value.training_id) {
            this.project.training.training_id = data.value.training_id as ITraining;
            this.project.training.training_id.visible = createTrainingStore.training.general.visibility ?? false;
          } else if (data.value.title) {
            this.project.training.project_title = data.value.title;
          }

          this.project.session_item.session_item_id = data.value.session_item_id;
          this.project.session_item.session_item_type = data.value.session_item_type;

          const { session_id } = data.value;
          this.project.session.session_id = session_id;

          if (data.value.project_users?.length) {
            const userDatas = data.value.project_users.find((u: any) => u.user_id?.user_id === user_id);
            this.project.users.users_id = [userDatas.user_id];
            const buckets: Bucket[] = userDatas?.buckets?.map((b: UserBuckets) => {
              return {
                amount: b?.price_excl_tax,
                financing_mode: b?.financing_mode.name,
              };
            });
            const usersBuckets = {
              user_id: userDatas.user_id.user_id,
              display: userDatas.user_id.display_value,
              budget: buckets?.find((b) => b.financing_mode === FinancingModes.BUDGET)?.amount ?? null,
              cpf: buckets?.find((b) => b.financing_mode === FinancingModes.CPF)?.amount ?? null,
              opco: buckets?.find((b) => b.financing_mode === FinancingModes.OPCO)?.amount ?? null,
              total:
                (buckets?.find((b) => b.financing_mode === FinancingModes.BUDGET)?.amount || 0) +
                (buckets?.find((b) => b.financing_mode === FinancingModes.CPF)?.amount || 0) +
                (buckets?.find((b) => b.financing_mode === FinancingModes.OPCO)?.amount || 0),
            };
            this.project.financing.users_buckets = [usersBuckets];

            this.loadUsersFinancing();
          }
          if (this.project.session_item.session_item_type) {
            this.setSessionItemsSessions(this.project.session_item?.session_item_type, true);
          }

          this.project.financing.budget_excl_tax =
            Number(
              data.value?.buckets?.find((b: any) => b.financing_mode.name === FinancingModes.BUDGET)?.price_excl_tax,
            ) || null;
          this.project.financing.opco_excl_tax =
            Number(
              data.value?.buckets?.find((b: any) => b.financing_mode.name === FinancingModes.OPCO)?.price_excl_tax,
            ) || null;
          this.project.financing.cpf_excl_tax =
            Number(
              data.value?.buckets?.find((b: any) => b.financing_mode.name === FinancingModes.CPF)?.price_excl_tax,
            ) || null;
          this.project.financing.price_excl_tax = Number(
            (this.project.financing.budget_excl_tax || 0) +
              (this.project.financing.opco_excl_tax || 0) +
              (this.project.financing.cpf_excl_tax || 0),
          );
          if (data.value.reporting_period) {
            this.project.reportingPeriod = data.value.reporting_period;
          }

          this.project.notification.do_notify_main_contact = !!data.value.training_partner_email;
          this.project.notification.main_contact_email = data.value.training_partner_email ?? null;
        }
        this.fetch_complete_done = true;
      }
    },
    async processCompleteSessionDetail(id: string, noReset?: boolean, isSession?: boolean) {
      const projectsApi = useProjectApi();
      const createTrainingStore = useCreateTrainingStore();
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, SessionsApi);

      if (!noReset) {
        this.$reset();
      }

      this.fetch_complete_done = false;

      let session: any = null;
      if (isSession) {
        session = await apiInstance.sessionsControllerGetDetails({ sessionId: id });
      } else {
        session = await projectsApi.findDetailsProjectIndividual(id);
      }

      if (session) {
        const s = isSession ? session : session.data.value;

        this.project.project_id = !isSession ? s.project_id : null;
        if (!s.training_id) {
          this.project.training.project_title = s?.title;
        }
        this.project.source_id = s?.source_id;
        this.project.source_type = s?.source_type;

        if (!isSession) {
          this.project.training.training_id = (s.training_id as ITraining) || null;
        } else {
          this.project.training.training_id = s.session_item_id.training as ITraining;
        }

        if (this.project.training.training_id) {
          this.project.training.training_id.visible = createTrainingStore.training.general.visibility || false;
        }

        this.project.session_item.session_item_id = s.session_item_id;
        this.project.session_item.session_item_type = !isSession ? s.session_item_type : s.session_item_id.type;

        if (this.project.session_item.session_item_type) {
          this.setSessionItemsSessions(this.project.session_item?.session_item_type, true);
        }
        if (this.project.overbooking_not_allowed === null) {
          this.project.overbooking_not_allowed = false;
        }
        this.project.session.session_id = s.session_id;

        if (this.project.session_item.session_item_type === SessionItemTypeEnum.GROUP) {
          this.project.financing.budget_excl_tax = Number.isFinite(
            s?.buckets?.find((b: any) => b.financing_mode.name === "budget")?.price_excl_tax,
          )
            ? Number(s?.buckets?.find((b: any) => b.financing_mode.name === "budget")?.price_excl_tax)
            : null;
          this.project.financing.opco_excl_tax = Number.isFinite(
            s?.buckets?.find((b: any) => b.financing_mode.name === "opco")?.price_excl_tax,
          )
            ? Number(s?.buckets?.find((b: any) => b.financing_mode.name === "opco")?.price_excl_tax)
            : null;
          this.project.financing.cpf_excl_tax = Number.isFinite(
            s?.buckets?.find((b: any) => b.financing_mode.name === "cpf")?.price_excl_tax,
          )
            ? Number(s?.buckets?.find((b: any) => b.financing_mode.name === "cpf")?.price_excl_tax)
            : null;

          this.project.financing.price_excl_tax = Number(
            (this.project.financing.budget_excl_tax || 0) +
              (this.project.financing.opco_excl_tax || 0) +
              (this.project.financing.cpf_excl_tax || 0),
          );
        }

        if (!isSession) {
          this.project.financing.users_buckets = s.project_users?.map((u: any) => {
            const budget = u?.buckets?.find((b: any) => b.financing_mode.name === "budget")?.price_excl_tax;
            const cpf = u?.buckets?.find((b: any) => b.financing_mode.name === "cpf")?.price_excl_tax;
            const opco = u?.buckets?.find((b: any) => b.financing_mode.name === "opco")?.price_excl_tax;

            return {
              user_id: u.user_id?.user_id,
              display:
                u.user_id?.firstname && u.user_id?.lastname
                  ? `${u.user_id.firstname} ${u.user_id.lastname}`
                  : u.user_id?.email ?? u.user_id?.fullname,
              budget: Number.isFinite(budget) ? Number(budget) : null,
              cpf: Number.isFinite(cpf) ? Number(cpf) : null,
              opco: Number.isFinite(opco) ? Number(opco) : null,
              total: null,
            };
          });

          this.project.users.inscribed = s.project_users?.map((pu: any) => {
            return {
              ...pu.user_id,
              id: pu.id,
              status: s.status,
            };
          });
          this.project.users.users_id = s.project_users.map((pu: any) => {
            const { manager_id, ...data } = pu.user_id;
            return {
              ...data,
              status: s.status,
              manager_email: manager_id?.email ?? null,
              manager_display_value: manager_id?.display_value ?? null,
            };
          });
        } else {
          /** session */
          this.project.financing.users_buckets = s.bookings?.map((u: any) => {
            return {
              user_id: u.user_id?.user_id,
              display: u.user_id?.display_value,
              budget: Number.isFinite(u.budget_excl_tax) ? Number(u.budget_excl_tax) : null,
              cpf: Number.isFinite(u.cpf_excl_tax) ? Number(u.cpf_excl_tax) : null,
              opco: Number.isFinite(u.opco_excl_tax) ? Number(u.opco_excl_tax) : null,
              total: null,
            };
          });

          this.project.users.inscribed = s.bookings?.map((pu: any) => {
            return {
              ...pu.user_id,
              id: pu.id,
              status: s.status,
            };
          });
          this.project.users.users_id = s.bookings.map((pu: any) => {
            return { ...pu.user_id, status: s.status };
          });
        }

        if (s.reporting_period) {
          this.project.reportingPeriod = s.reporting_period;
        }
        if (this.project.overbooking_not_allowed === null) {
          this.project.overbooking_not_allowed = false;
        }

        this.project.notification.do_notify_main_contact = !!s.training_partner_email;
        this.project.notification.main_contact_email = s.training_partner_email ?? null;
      }

      this.fetch_complete_done = true;
    },
  },
});
